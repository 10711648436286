<template>
  <base-view>
    <view-header title="Calculators" image="about.jpg">
      Here to help you make the best decisions going forward.
    </view-header>
    <view-section>
      <calculator-group
        title="Savings Calculators"
        :forms="formGroups['Main Forms']"
      />
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewHeader from "@/components/layout/ViewHeader.vue";
import ViewSection from "@/components/layout/ViewSection.vue";
import CalculatorGroup from "./components/CalculatorGroup.vue";

export default {
  components: {
    BaseView,
    ViewHeader,
    ViewSection,
    CalculatorGroup
  },
  data() {
    return {
      formGroups: {
        "Main Forms": [
          {
            url: "https://hsastore.com/hsa-tax-savings-calculator",
            title: "HSA Calculator",
            id: "1"
          },
          {
            url: "https://fsastore.com/fsa-calculator",
            title: "FSA Calculator",
            id: "2"
          }
          // {
          //   url:
          //     "https://learn.myhealthyfinances.com/plan-comparison?name=sample%20client",
          //   title: "Plan Comparison Calculator",
          //   id: "3"
          // }
        ]
      }
    };
  }
};
</script>

<style scoped>
.form-link:nth-last-of-type(2):nth-child(odd),
.form-link:last-of-type {
  border-bottom: 0;
}

/* .form-link:nth-of-type(4n),
  .form-link:nth-of-type(4n-1) {
    background: theme("colors.neutral.50");
  }
  
  .form-link:nth-of-type(4n):hover,
  .form-link:nth-of-type(4n-1):hover {
    background: theme("colors.neutral.100");
  } */
</style>
